import { render, staticRenderFns } from "./CampaignCard.vue?vue&type=template&id=17a9299f"
import script from "./CampaignCard.vue?vue&type=script&lang=js"
export * from "./CampaignCard.vue?vue&type=script&lang=js"
import style0 from "./CampaignCard.vue?vue&type=style&index=0&id=17a9299f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports